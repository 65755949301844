import consumer from './consumer';

let resetFunc;
let timer = 0;
let currentStatus = '';
let lastSeenUpdatedAt;

const ONLINE_STATUS = 'online';
const OFFLINE_STATUS = 'offline';
const AWAY_STATUS = 'away';
const DELAY_IN_SECONDS = 120;

consumer.subscriptions.create(
  { channel: 'AppearanceChannel' },
  {
    initialized() {},
    connected() {
      resetFunc = () => this.resetTimer(this.uninstall);
      this.install();
      window.addEventListener('turbo:load', () => this.resetTimer());
    },
    disconnected() {
      this.uninstall();
    },
    rejected() {
      this.uninstall();
    },
    online() {
      this.perform(ONLINE_STATUS);
    },
    away() {
      let viewingConversation = document.getElementById('conversation-detail');
      if (viewingConversation) {
        let conversationId = viewingConversation.dataset.conversationId;
        this.perform('update_participant_last_read_at', { conversation_id: conversationId });
      }
      this.perform(AWAY_STATUS);
      this.setCurrentStatus(AWAY_STATUS);
    },
    uninstall() {
      const shouldRun = document.getElementById('appearance_channel');
      if (!shouldRun) {
        clearTimeout(timer);
        this.perform(OFFLINE_STATUS);
        this.setCurrentStatus(OFFLINE_STATUS);
      }
    },
    setLastSeenAt() {
      if (!lastSeenUpdatedAt) {
        lastSeenUpdatedAt = new Date();
        this.performLastSeenAt();
      }

      let currentTime = new Date();
      let waitTime = lastSeenUpdatedAt.getTime() + DELAY_IN_SECONDS * 1000;
      if (waitTime < currentTime.getTime()) {
        lastSeenUpdatedAt = new Date();
        this.performLastSeenAt();
      }
    },
    performLastSeenAt() {
      this.perform('last_seen_at', {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    },
    isOnline() {
      return currentStatus === ONLINE_STATUS;
    },

    isVisible() {
      return document.visibilityState === 'visible';
    },
    install() {
      // Remove existing eventListener so that we don't bloat browser will mulitple listeners
      window.removeEventListener('load', resetFunc);
      window.removeEventListener('DOMContentLoaded', resetFunc);
      window.removeEventListener('visibilitychange', resetFunc);
      window.removeEventListener('click', resetFunc);
      window.removeEventListener('focus', resetFunc);

      // Add eventListeners to change the status of the user
      window.addEventListener('load', resetFunc);
      window.addEventListener('DOMContentLoaded', resetFunc);
      window.addEventListener('visibilitychange', resetFunc);
      window.addEventListener('click', resetFunc);
      window.addEventListener('focus', resetFunc);

      this.resetTimer();
    },
    resetTimer() {
      this.uninstall();
      if (this.isVisible()) {
        if (!this.isOnline()) {
          this.online();
        }
        this.setLastSeenAt();
        this.setCurrentStatus(ONLINE_STATUS);
        this.resetAwayTimer();
      }
    },
    resetAwayTimer() {
      clearTimeout(timer);
      // waits for 60 seconds before moving the status to `away`
      const timeInMilliseconds = DELAY_IN_SECONDS * 1000;
      timer = setTimeout(this.away.bind(this), timeInMilliseconds);
    },
    setCurrentStatus(newStatus) {
      currentStatus = newStatus;
    },
    received(data) {
      let userStatusElements = Array.from(
        document.getElementsByClassName(`user_${data['user_id']}_online_status`)
      );

      userStatusElements.forEach((element) => {
        element.classList.remove(data['old_status_class']);
        element.classList.add(data['new_status_class']);
      });
    }
  }
);
