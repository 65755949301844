import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['phoneNumber', 'button'];
  connect() {
    this.buttonTarget.setAttribute('disabled', true);
    this.validateForm();
  }

  validateForm() {
    let status = false;
    if (this.validatePhoneNumber()) {
      status = true;
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', true);
    }
    return status;
  }

  validatePhoneNumber() {
    const value = this.phoneNumberTarget.value;
    return value && value.length >= 10;
  }
}
