import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-advanced-image-select"
export default class extends Controller {
  static targets = ['selectModel', 'selectSize', 'selectAspectRatio', 'imageBlurHolder'];

  onSelectedModel() {
    if (this.selectModelTarget.value) {
      const selected_model = this.selectModelTarget.value;
      post('/ai/image/advanced_settings_for_model', {
        query: { selected_model },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectedSize() {
    if (this.selectSizeTarget.value && this.selectModelTarget.value) {
      const selected_size = this.selectSizeTarget.value;
      const selected_model = this.selectModelTarget.value;
      post('/ai/image/advanced_settings_for_model', {
        query: { selected_size, selected_model },
        responseKind: 'turbo-stream'
      });
    }
  }

  onInitImageStrengthChange(event) {
    let value = parseInt(event.target.value);
    if (value < 0 || value > 100) return;
    this.imageBlurHolderTarget.style.setProperty('--image-strength-blur', value);
  }

  getExistingInitImageSignedId() {
    return document.querySelector('#dropzone_image')?.dataset?.signedId;
  }

  onSelectAspectRatio() {
    let aspect_ratio = this.selectAspectRatioTarget.value;
    let model = this.selectModelTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    post('/ai/image/update_preview_and_values', {
      query: { aspect_ratio, model },
      responseKind: 'turbo-stream'
    });
    if (signedId) {
      post('/ai/image/update_init_image_preview', {
        query: { signed_id: this.getExistingInitImageSignedId(), aspect_ratio },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectPortraitAspectRatio() {
    let ar = this.selectAspectRatioTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    if (signedId) {
      post('/ai/video/update_portrait_image_preview', {
        query: { signed_id: signedId, aspect_ratio: ar },
        responseKind: 'turbo-stream'
      });
    }
  }

  onSelectClipSourceAspectRatio() {
    let ar = this.selectAspectRatioTarget.value;
    let signedId = this.getExistingInitImageSignedId();
    let pathName = window.location.pathname;
    if (signedId) {
      post('/ai/image/update_source_image_preview', {
        query: { signed_id: signedId, aspect_ratio: ar, url_path: pathName },
        responseKind: 'turbo-stream'
      });
    }
  }
}
