/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { Tooltip } from 'bootstrap';
import { findElement } from '../helpers';

// Connects to data-controller="tooltip-init"
export default class extends Controller {
  connect() {
    let tooltip = findElement(this.element, '[data-bs-toggle="tooltip"]');

    new Tooltip(tooltip, { container: this.element });
    // cleanup after tooltip init
    this.element.removeAttribute('data-controller');
  }
}
