/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { subscribe, unsubscribe } from '../helpers/eventbus';

const NAMESPACE = 'opportunity[cover_image]';

export default class extends Controller {
  connect() {
    // We subscribe to files being added/removed from Dropzone; we verify they are for the hiddenInput for the CoverImage, and change the css classes based on that
    this.onFileAddedSubscription = subscribe('dropzone-addedfile', this.onFileAdded.bind(this));
    this.onFileRemovedSubscription = subscribe(
      'dropzone-removedfile',
      this.onFileRemoved.bind(this)
    );
  }

  disconnect() {
    unsubscribe('dropzone-addedfile', this.onFileAddedSubscription);
    unsubscribe('dropzone-removedfile', this.onFileRemovedSubscription);
  }

  onFileAdded(params) {
    if (params.fileHiddenInputName !== NAMESPACE) {
      return;
    }
    this.element.classList.add('cover-image-detected');
  }

  onFileRemoved(params) {
    if (params.fileHiddenInputName !== NAMESPACE) {
      return;
    }
    this.element.classList.remove('cover-image-detected');
  }

  switchToSearch() {
    this.element.classList.add('display-search-only');
    this.element.classList.remove('display-drop-zone-only');
  }

  switchToFileUpload() {
    let removeTarget =
      document.querySelector('[data-dz-remove]') || document.querySelector('#removeFile');
    if (removeTarget) {
      removeTarget.click();
    }
  }
}
