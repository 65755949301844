import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';
import { post } from '@rails/request.js';
import { Modal } from 'bootstrap';
import { emit, subscribe, unsubscribe } from '../helpers/eventbus';

// this controller needs to be decalred on a form element for the turbo events to be listened for
export default class extends Controller {
  static targets = [
    'prompt',
    'button',
    'resetSearchButton',
    'creditsBalance',
    'baseVideoPrice',
    'baseAudioPrice',
    'baseImagePrice',
    'socialPostPrice',
    'otherTextPrice',
    'textType',
    'isVideoGeneration',
    'isAudioGeneration',
    'isImageGeneration',
    'isTextGeneration',
    'isSocialPost',
    'isHistory',
    'imageCount',
    'textCount',
    'costButtonValue',
    'generateImagesButton',
    'create',
    'totalCost',
    'popupTrigger',
    'isChatGeneration',
    'placeholderImages',
    'imageGenerationType',
    'filtersHiddenSearch',
    'audioTextCount',
    'speechOption',
    'isFree'
  ];

  static values = {
    isShowPage: Boolean,
    url: String
  };

  textTypePrices = {
    // social post options
    Instagram: 10,
    TikTok: 10,
    Threads: 10,
    Youtube: 10,
    Twitter: 10,
    Facebook: 10,
    Twitch: 10,
    Blog: 25,
    Custom: 10,
    // rewrite options
    'Instagram Post': 10,
    Tweet: 10,
    'Threads Post': 10,
    'Facebook Post': 10,
    'TikTok Post': 10,
    'Youtube Description': 10,
    'Twitch Description': 10,
    'Blog Article': 25,
    Elaborate: 10,
    Summarize: 10
  };

  chatTypePrices = {
    chat_gpt: 1
  };

  lastSearchString = '';
  popupModal = null;
  costButtonDefaultClass = null;
  audioScriptSetSubscription = null;

  connect() {
    this.audioScriptSetSubscription = subscribe(
      'ai-audio-script-set',
      this.validateForm.bind(this)
    );

    if (this.hasCostButtonTarget) {
      this.costButtonDefaultClass = this.costButtonTarget.getAttribute('class');
    }
    if (this.hasButtonTarget) {
      this.bindEvents();
      if (this.hasPromptTarget && !this.validateCreatePrompt()) {
        this.buttonTarget.setAttribute('disabled', true);
      }
    }
    this.onSearchInputChangedDebounce = debounce(
      (path) => this.onSearchInputChangedDebounced(path),
      400
    );
    if (this.hasResetSearchButtonTarget) {
      this.lastSearchString = this.promptTarget.value;
      this.onSearchInputChanged();
    }
    if (
      this.hasPopupTriggerTarget &&
      ['creator-subscription', 'marketer-subscription'].includes(this.popupTriggerTarget.value)
    ) {
      let ele = document.getElementById(this.popupTriggerTarget.value);
      this.popupModal = new Modal(ele);
    }

    this.validateForm(null);
  }

  disconnect() {
    unsubscribe('ai-audio-script-set', this.audioScriptSetSubscription);
  }

  onFocusChange() {
    emit('mobile-view-changed');
  }

  validateForm(event) {
    let status = false;
    if (this.isAudioGeneration()) {
      this.updateAudioTextCounter();
    }
    this.updateCostButton(event === null);
    this.updateGenerateImages();
    if (this.popupModal && !this.canAfford()) {
      this.popupModal.show();
    }

    if (this.hasButtonTarget) {
      let hasDisabledWhileCreating =
        this.buttonTarget.classList.value.includes('disabled-while-creating');
      if (
        this.validateCreatePrompt() &&
        this.canAfford() &&
        this.validCost() &&
        !hasDisabledWhileCreating
      ) {
        status = true;
        this.buttonTarget.removeAttribute('disabled');
      } else {
        this.buttonTarget.setAttribute('disabled', true);
      }
      return status;
    }
    return false;
  }

  resetSearchInput() {
    this.promptTarget.value = '';
    if (this.hasFiltersHiddenSearchTarget) {
      this.filtersHiddenSearchTarget.value = '';
    }

    this.onSearchInputChanged();
    this.onSearchInputChangedDebounced();
  }
  onSearchInputChanged(event) {
    let path = event && event.currentTarget ? event.currentTarget.dataset.path : null;
    // if event has path use it for search post instead
    if (!this.hasResetSearchButtonTarget) {
      return;
    }

    // we want immediate feedback on the clear button
    if (this.promptTarget.value) {
      this.resetSearchButtonTarget.classList.remove('d-none');
    } else {
      this.resetSearchButtonTarget.classList.add('d-none');
    }

    // but the actual searching is debounced
    this.onSearchInputChangedDebounce(!path ? null : path);
  }

  isAiShowcasePage() {
    return window.location.pathname.startsWith('/ai/community');
  }

  async onSearchInputChangedDebounced(path) {
    let value = this.promptTarget.value;

    // Don't research for current search
    if (value === this.lastSearchString) return;
    this.lastSearchString = value;

    // Other than the Community page, if we're on the show page, we want to be full page redirected to the index page when searching
    // @Soli: This seems to be unnecessary - as a show route for image/text has no search bar any longer (in community or history afaik)
    // if (this.isShowPageValue && !this.isAiShowcasePage()) {
    //   // set url to up one path (so the index of image/text)
    //   let url = initial(window.location.href.split('/')).join('/');
    //   window.location.href = `${url}?search=${value}`;
    //   return;
    // }

    // Parse the current url params, update the search queryParam, and replace the url without hard navigation
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('page');

    if (value) {
      urlParams.set('search', value);
    } else {
      urlParams.delete('search');
    }

    let searchPath = '/ai/histories/search';
    if (path) {
      searchPath = path;
    } else if (this.isAiShowcasePage()) {
      searchPath = '/ai/community/search';
    }

    if (window.location.href.includes('/community/manage')) {
      await post('/ai/community/manage/search', {
        // Maintain current url params, but update search
        query: Object.assign({}, Object.fromEntries(urlParams.entries()), {
          search: value
        }),
        responseKind: 'turbo-stream'
      });
    } else if (window.location.href.includes('/audio/voice_library')) {
      await post('/ai/audio/search', {
        query: Object.assign({}, Object.fromEntries(urlParams.entries()), {
          search: value
        }),
        responseKind: 'turbo-stream'
      });
    } else {
      await post(searchPath, {
        query: {
          search: value,
          category_type: this.categoryType()
        },
        responseKind: 'turbo-stream'
      });
    }

    // Ensure it ends with a / so that it works with searching AI Community Manage
    let pathName = window.location.pathname.endsWith('/')
      ? window.location.pathname
      : window.location.pathname + '/';

    let newUrl = `${window.location.origin}${pathName}${
      urlParams.size > 0 ? '?' + urlParams.toString() : ''
    }`;
    window.history.replaceState(null, '', newUrl);
  }

  validateCreatePrompt() {
    if (
      this.isAudioGeneration() &&
      this.hasSpeechOptionTarget &&
      this.speechOptionTarget === 'speech_to_speech'
    ) {
      return true;
    }

    const value = this.promptTarget.value.trim();
    const isSpeechToSpeechOption = this.buttonTarget?.dataset?.speechOption === 'speech_to_speech';

    const imageGenerationWithNoPrompt = ['reimagine', 'remove_background'];
    if (
      (imageGenerationWithNoPrompt.includes(this.imageGenerationType()) &&
        this.hasUploadedImage()) ||
      isSpeechToSpeechOption
    ) {
      return true;
    }

    return value && value.length >= 1;
  }

  categoryType() {
    return document.getElementById('history_category_type').value;
  }

  bindEvents() {
    this.element.addEventListener('turbo:submit-start', () => {
      this.buttonTarget.classList.add('disabled-while-creating');
      this.promptTarget.setAttribute('disabled', true);
    });

    this.element.addEventListener('turbo:submit-end', () => {
      this.buttonTarget.classList.remove('disabled-while-creating');
      this.promptTarget.removeAttribute('disabled');
    });
  }

  // if we are on a video page
  isVideoGeneration() {
    return this.hasIsVideoGenerationTarget && this.isVideoGenerationTarget.value === 'true';
  }

  // if we are on a audio page
  isAudioGeneration() {
    return this.hasIsAudioGenerationTarget && this.isAudioGenerationTarget.value === 'true';
  }

  // if we are on an image page
  isImageGeneration() {
    return this.hasIsImageGenerationTarget && this.isImageGenerationTarget.value === 'true';
  }

  imageGenerationType() {
    return this.isImageGeneration() && this.imageGenerationTypeTarget.value;
  }

  // if we are on a text page
  isTextGeneration() {
    return this.hasIsTextGenerationTarget && this.isTextGenerationTarget.value === 'true';
  }

  // if we are on chat page
  isChatGeneration() {
    return this.hasIsChatGenerationTarget && this.isChatGenerationTarget.value === 'true';
  }

  // if we are viewing a history and not currently generating
  isHistory() {
    return this.hasIsHistoryTarget && this.isHistoryTarget.value === 'true';
  }

  // the amount of ai we are looking at, i.e. batch_size
  count() {
    if (this.isImageGeneration()) {
      if (this.imageGenerationType() === 'remove_background') {
        return Array.from(document.querySelectorAll('#dropzone_image')).length;
      }
      if (this.imageGenerationType() === 'dreamscale') {
        return 1;
      }
      if (this.hasImageCountTarget) {
        // if we are unable to parse, we are using 0 instead of NaN on the frontend
        return Number(this.imageCountTarget.value) || 0;
      }
    }
    if (this.isTextGeneration()) {
      if (this.hasTextCountTarget) {
        return Number(this.textCountTarget.value) || 0;
      }
      return 1;
    }

    if (this.isChatGeneration()) {
      return 1;
    }

    if (this.isVideoGeneration()) {
      return 1;
    }

    if (this.isAudioGeneration()) {
      return this.promptTarget.value.length;
    }
  }

  // base cost of the ai that we are looking at
  baseCost() {
    if (this.isImageGeneration()) {
      return parseInt(this.baseImagePriceTarget.value);
    }
    if (this.isTextGeneration()) {
      if (this.hasTextTypeTarget) {
        if (this.textTypeTarget.value) {
          return this.textTypePrices[this.textTypeTarget.value];
        }
        return this.textTypePrices[this.getDropdownDefault(this.textTypeTarget)]; // use the placeholder option
      }
      return 25; // it is not a rewrite or social post (therefore its a product review/brand pitch/ etc.)
    }

    if (this.isChatGeneration()) {
      return 2;
    }

    if (this.isVideoGeneration()) {
      return parseInt(this.baseVideoPriceTarget.value);
    }

    if (this.isAudioGeneration()) {
      return parseInt(this.baseAudioPriceTarget.value);
    }

    return null;
  }

  // the cost of the ai that we are looking at
  cost() {
    if (this.isAudioGeneration()) {
      return Math.ceil(this.count() / 10.0) * this.baseCost();
    }
    return this.count() * this.baseCost();
  }

  // just used to disable generation if the user doesnt have a quantity entered
  validCost() {
    if (this.isAudioGeneration()) return true;

    if (this.hasIsFreeTarget && this.isFreeTarget.value === 'true') {
      return true;
    }
    return this.cost() > 0;
  }

  // the users credit balance
  balance() {
    return parseInt(this.creditsBalanceTarget.value);
  }

  // if the user can afford to purchase what they have selected
  canAfford() {
    if (this.isHistory() || (this.hasIsFreeTarget && this.isFreeTarget.value === 'true')) {
      // if we are on a history route we can afford the art as its already purchased and dont need to disable the  button
      return true;
    }

    return this.balance() >= this.cost();
  }

  updateAudioTextCounter() {
    if (this.hasAudioTextCountTarget) {
      this.audioTextCountTarget.innerText = this.count();
    }
  }

  updateCostButton(initial, modelChanged = false) {
    if (this.hasTotalCostTarget && initial) {
      let value = Number(this.totalCostTarget.dataset.totalValue);

      if (this.hasCostButtonValueTarget && value > 0 && !modelChanged) {
        this.costButtonValueTarget.innerHTML = `${this.totalCostTarget.dataset.totalValue}`;
        return;
      } else if (this.cost() > 0 && this.canAfford()) {
        this.costButtonValueTarget.innerHTML = `${this.cost()}`;
        return;
      }
    }

    if (this.hasCostButtonValueTarget) {
      if (this.canAfford()) {
        this.costButtonValueTarget.innerHTML = `${this.cost()}`;
        if (this.costButtonDefaultClass) {
          this.costButtonTarget.setAttribute('class', `${this.costButtonDefaultClass} pe-none`);
        }
      } else {
        this.costButtonValueTarget.innerHTML = 'Insufficient Credits';
        if (this.costButtonDefaultClass) {
          this.costButtonTarget.setAttribute('class', `${this.costButtonDefaultClass}`);
        }
      }
    }
  }

  // this is for purchasing extra images on a text generation
  canAffordImages() {
    let baseCost = parseInt(this.baseImagePriceTarget.value);
    let totalCost = baseCost * 6; // the action generates 6 images
    let balance = this.balance();

    return balance >= totalCost;
  }

  // disable the generate images button if the user cannot afford it
  updateGenerateImages() {
    if (this.hasGenerateImagesButtonTarget) {
      if (!this.canAffordImages()) {
        this.generateImagesButtonTarget.setAttribute('disabled', true);
      }
    }
  }

  updateDefaultImageCount() {
    if (this.urlValue && this.hasImageCountTarget && this.hasPlaceholderImagesTarget) {
      const response = post(this.urlValue, {
        query: { image_count: this.imageCountTarget.value },
        responseKind: 'turbo-stream'
      });
      if (response.ok) {
        console.log('Image generation count updated');
      }
    }
  }

  baseImagePriceTargetConnected() {
    this.updateCostButton(true, true);
  }

  // gets the default option of a dropdown
  getDropdownDefault(target) {
    return JSON.parse(target.getAttribute('data-hs-tom-select-options'))['items'][0];
  }

  // check if clipdrop source image or images exist for those generation type that does not use prompt
  hasUploadedImage() {
    return (
      document.querySelector('#dropzone_image')?.dataset?.signedId != null ||
      Array.from(document.querySelectorAll('#dropzone_image')).length > 0
    );
  }

  speechAudioRemoved() {
    post('/ai/audio/speech_audio_removed', {
      responseKind: 'turbo-stream'
    });
  }

  speechVoiceUploaded() {
    if (this.hasButtonTarget) {
      let hasDisabledWhileCreating =
        this.buttonTarget.classList.value.includes('disabled-while-creating');
      if (this.canAfford() && !hasDisabledWhileCreating) {
        this.buttonTarget.removeAttribute('disabled');
      } else {
        this.buttonTarget.setAttribute('disabled', true);
      }
    }
  }

  isSpeechToSpeechOption() {
    this.buttonTarget?.dataset?.speechOption === 'speech_to_speech';
  }

  preventSubmit(event) {
    event.preventDefault();
  }
}
