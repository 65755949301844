import EventBus from 'js-event-bus';

const eventBus = new EventBus();

export function subscribe(eventName, callback){
  eventBus.on(eventName, callback);
  return callback;
}
export function unsubscribe(eventName, callback){
  eventBus.detach(eventName, callback);
}
export function emit(eventName, params){
  eventBus.emit(eventName, null, params);
}
